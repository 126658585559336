import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { space } from "styled-system"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { HeaderSpacer, Layout, LeadText, SEO } from "../components"

import { P, H1 } from "../components/Text"

const ImageSection = styled.div`
  background-image: linear-gradient(white 50%, #f8f8f7 50%);
  & img {
    width: 100%;
  }
`
const TextSection = styled.div`
  background-color: #f8f8f7;
  ${space};
`

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="We are 327" image="/studio2.jpg" />
      <HeaderSpacer />
      <LeadText
        title="Design with a purpose"
        text="At the core of our creative process is a commitment to simplicity and craftsmanship, driving us to create tailor-made branding experiences."
      />
      <ImageSection>
        <Container>
          <Row>
            <Col xs={10} offset={1}>
              <img src="/studio2.jpg" alt="" />
            </Col>
          </Row>
        </Container>
      </ImageSection>
      <TextSection py={[4, 4, 5]} mb={[3, 4]}>
        <Container>
          <Row>
            <Col xs={12} sm={4}>
              <H1 as="h2" fontWeight="bold" mb={1}>
                Studio.
              </H1>
              <P mb={3}>
                327 creative studio is an independent branding studio based in
                Porto, Portugal. Founded in 2011, we combine purposeful and
                compelling design with branding know-how and an array of visual
                languages to achieve meaningful and effective results, where
                details play a major role.
              </P>
              <P mb={3}>
                Branding [print & digital], Packaging and Editorial have been
                the core of our creative pursuit, yet our passion has led us in
                several directions that driven us to work on projects covering a
                wide spectrum of clients, from family-oriented businesses to
                major companies. Regardless of our client scope, we like to be
                small and take attention to detail very seriously — call us a
                design boutique.
              </P>
              <P>
                At the heart of our creative process is a commitment to
                simplicity and craftsmanship, allowing us to create tailor-made
                solutions and great branding experiences. Our creative team
                embraces each project with open-minded communication, building
                strong, close relationships with every client.
              </P>
            </Col>
            <Col xs={6} sm={3} offset={{ xs: 0, sm: 2 }}>
              <H1 as="h2" fontWeight="bold" mb={1} mt={[4, 0]}>
                Team.
              </H1>
              <P>
                <span style={{ textDecoration: "underline" }}>
                  Mafalda Portal
                </span>
                <br />
                / Founder & Creative Director
                <br />
                <br />
                <span style={{ textDecoration: "underline" }}>
                  Kristin Stachl
                    </span>
                <br />
                    / Brand Designer
                <br />
                <br />
                <span style={{ textDecoration: "underline" }}>
                  Maria Ruiz
                    </span>
                <br />/ Brand Designer
              </P>
              <H1 as="h2" fontWeight="bold" mt={4} mb={1}>
                In Collaboration.
              </H1>
              <P>
                João Belo
                <br />
                Luís Carvalho
                <br />
                Hugo Vaz
                <br />
                Álvaro Martino
                <br />
                Soraia Martins
              </P>
              <H1 as="h2" fontWeight="bold" mt={4} mb={1}>
                Services.
              </H1>
              <P>
                Art Direction
                <br />
                Books and Publications
                <br />
                Branding
                <br />
                Brand Positioning + Storytelling  
                <br />
                Identity Systems
                <br />
                Infographics
                <br />
                Label Design
                <br />
                Naming
                <br />
                Packaging
                <br />
                Print & Production
                <br />
                Signage + Wayfinding 
                <br />
                Visual Identities 
                <br />
                Web Design + Development
              </P>
            </Col>
            <Col xs={5} sm={2} offset={1}>
              <H1 as="h2" fontWeight="bold" mb={1} mt={[4, 0]}>
                Selected Clients.
              </H1>
              <P>
                Adalberto
                <br />
                Afaplan
                <br />
                Almeida Garrett Wines
                <br />
                Aorp
                <br />
                Baby Gi — Newborn Clothing
                <br />
                Câmara Municipal do Porto
                <br />
                Combi
                <br />
                Coolskin 
                <br />
                Hotel Douro Scala 
                <br />
                Edp
                <br />
                Flik Sekt Manufaktur
                <br />
                GoParity
                <br />
                Graham’s
                <br />
                Herdade Malhadinha Nova
                <br />
                Hotel Meira
                <br />
                Janssen
                <br />
                João Portugal Ramos 
                <br />
                Lemon Jelly
                <br />
                MVCA Family Estates
                <br />
                Namaskar Ayurved
                <br />
                Omassi
                <br />
                Porto Protocol Foundation
                <br />
                Portugal Boutique Winery
                <br />
                Predibisa
                <br />
                Quinta de Valbom
                <br />
                Quinta do Cabeço Trigal 
                <br />
                Riopele
                <br />
                Rock n Bowl
                <br />
                Servant
                <br />
                Sogrape
                <br />
                Stucco
                <br />
                Susana Bettencourt
                <br />
                Syminghton Family Estates
                <br />
                The Lodge Hotel
                <br />
                Tubarão Wines 
                <br />
                Turismo do Alentejo
                <br />
                Velocity Investments
                <br />
                Wuda
              </P>
            </Col>
          </Row>
        </Container>
      </TextSection>
      <LeadText
        title="Be part of the team."
        text="Show us what you got."
        link="mailto:work@327.pt"
      />
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
